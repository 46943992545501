import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../components/Footer';
import ContactBar from '../components/ContactBar';
import Contact from '../components/Contact';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';


const NotFound = ({ location }) => (
  <Layout location={location}>
    <div id="pricing" className="page with-sidebar">
      <Helmet>
        <title>404 - Seite nicht gefunden</title>
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <div className="content">
          <h1>Seite nicht gefunden</h1>
          <p>Sorry, diese Seite existiert nicht.</p>
        </div>
        <Sidebar />
      </div>
      <Contact />
      <Footer />
    </div>
  </Layout>
)

export default NotFound;